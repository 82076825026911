<template>
  <div>
    <!-- 轮播图 -->
    <div>
      <div class="personal">
        <van-swipe class="my-swipe"
          :autoplay="3000"
          indicator-color="white"
          style="border-radius: 10px;">
          <van-swipe-item class="homebanner"
            v-for="(item,index) in images"
            :key="index">
            <img :src="item"
              onerror="onerror=null;src='https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png'" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div style="width:93%;margin:0 auto;padding:15px;">
      <div style="line-height: 30px;">
        <span style="font-size: 18px;font-weight: 600;">{{orderPackage.SGNames}}<br /></span>
        <span>
          <span class="tagClass">服务等级：{{orderPackage.DemandLevelDesc}}</span>
        </span><br />
        <span v-if="orderPackage.SerDuration != null && orderPackage.SerDuration != ''">{{orderPackage.SerDuration}}<br /></span>
        <span style="color:red;font-size: 18px;font-weight: 600;">{{orderPackage.Cost}}<br /></span>
      </div>
      <van-button round
        style="bottom: 40px;float:right;width: 100px;"
        type="info"
        color="#ffc002"
        size="normal"
        @click="orderPlace(orderPackage.SPId)">选规格</van-button>
    </div>
    <div style="height: 20px;background: #f0f0f0;"></div>
    <div style="width:93%;margin:0 auto;line-height: 25px;">
      <div class="title"> — 详情 — </div>
      <div v-if="orderPackage.Content != ''"
        style="color: #474d54;margin-left: 10px;">
        <div style="margin-bottom: 5px;">服务内容:</div>
        <div>
          <div class="content">{{orderPackage.Content}}</div>
        </div>
      </div>
      <!-- <div v-if="orderPackage.Content != ''">
        <div style="margin-bottom: 5px;">辅助选项:</div>
        <div>
          <div class="content">{{orderPackage.Content}}</div>
        </div>
      </div> -->
      <div v-if="orderPackage.Remark != ''"
        style="color: #474d54;margin-left: 10px;">
        说明:<div>
          <div class="content">{{orderPackage.Remark}}</div>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="orderPlaceshow"
      title="已选商品">
      <div class="content">
        <div>
          <div v-for="item in orderplaceList"
            :key="item.SId">
            <div class="orderYx">
              <div style="float:left;width:25%;">
                <img :src="item.SImgUrl"
                  style="width:100%;"
                  class="van-image__img"
                  onerror="onerror=null;src='https://lycaljdapi.dexian.ren//upload/images/2021/12/3559e43a-6b7.png'" />
              </div>

              <!-- <div style="float:left;margin-left:10px;font-size: 14px;color: #646566;">
                <span style="line-height:25px;">
                  <span style="color: red;font-size: 18px;">￥{{item.SPrice}}{{item.SMUnit}}<br /></span>
                  <span>总价：{{item.SAmount}}<br /></span>
                  {{item.StaName}}</span>

              </div> -->
              <div class="orderRight">
                <span style="line-height:30px;">
                  <span style="font-size: 18px;font-weight: 600;"
                    class="van-ellipsis--l1">{{item.SName}}<br /></span>
                  <span style="color: red"><span style="font-size:16px;">￥{{item.SPrice}}</span> {{item.SMUnit}}<br /></span>
                  <!-- <span >{{item.SPrice}}<br /></span> -->
                  总价：￥{{item.SAmount}}</span>

              </div>
              <!-- <div style="float: left;width: 60%;margin: 5px;font-size: 16px;color: rgb(100, 101, 102);"
                class="van-multi-ellipsis--l2">{{item.SName}}</div> -->
              <div style="padding: 10px;margin-top: -30px;width: 26%;float: right;">
                <van-stepper v-model="item.SNum"
                  theme="round"
                  min="0"
                  style="right: 20px;text-align: right;"
                  button-size="22"
                  disable-input
                  @plus="changeAdd(item)"
                  @minus="changeDelet(item)"
                  @change="change" />
              </div>

            </div>

          </div>
        </div>
        <div style="width: 100%; ">
          <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
            <van-button round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 95%"
              color="black"
              @click="orderRen()">
              下 单
            </van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="orderRenshow"
      title="配送老人"
      @close='close()'>
      <div class="content">
        <van-radio-group v-model="oldManList"
          v-for="item in MyBindInfoList"
          :key="item.BindId"
          style="margin: 10px;padding:15px 10px;background: #f6f7f8;border-radius: 5px;">
          <van-radio :name="item.BindId"
            checked-color="#ffc002"
            style="padding: 20rpx;">
            <div style="font-size: 18px;font-weight: 600;margin-bottom: 10rpx;margin: 5px;">
              {{item.OMName}}
              <span style="font-weight: 500;">{{item.OMMobile}}</span>
              <span style="padding-left: 20px;"
                @click="updata(item)">
                <van-icon name="edit" /></span>
              <span style="padding-left: 20px;"
                @click="add()">
                <van-icon name="add-o" /></span>
            </div>
            <!-- <div style="font-size: 14px;margin-bottom: 10rpx;">{{item.Summary}}</div> -->
            <div style="margin: 5px;">{{item.OMAddr}}</div>
          </van-radio>
        </van-radio-group>
        <div style="">
          <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
            <van-button round
              type="primary"
              size="normal"
              style="font-size: 16px; width: 95%"
              color="black"
              @click="placeOrder()">
              确定
            </van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="orderRenAddshow"
      title="配送老人信息"
      @close='close()'>
      <div class="content">
        <van-field v-model="oldmanForm.OMIdCard"
          class="shuru"
          name="oldmanForm.OMIdCard"
          label="身份证号"
          placeholder="请输入身份证号" />
        <van-field v-model="oldmanForm.OMMobile"
          class="shuru"
          name="oldmanForm.OMMobile"
          label="联系电话"
          placeholder="请输入老人联系电话" />
        <van-field v-model="oldmanForm.OMAddr"
          type="textarea"
          rows="1"
          autosize
          class="shuru"
          name="oldmanForm.OMAddr"
          label="居住地址"
          placeholder="请输入老人现居住地址" />
        <div style="width: 90%; bottom: 0; position: fixed; padding: 10px">
          <van-button round
            block
            type="info"
            color="black"
            @click="submit()">保存</van-button>
        </div>
      </div>
    </van-action-sheet>
    <div class="hidden">没有更多了~</div>
  </div>
</template>

<script>
import {
  WeGenerateOrder,
  WeSaveOrderPackage,
  WeGetOrderServiceList,
  WeDeleteOrderService,
  WePlaceOrder,
  WeGetSerPackage,
  WeGetMyBindInfoPage,
  WeSaveBindInfo
} from "@/api/ylOrder.js";
import { setOpenId, getOpenId, setOrderId, getOrderId, removeOrderId } from "@/utils/auth";
import { WeGetAppletOrganList } from "@/api/Organ";
import Vue from 'vue';
import { Toast } from 'vant';

Vue.use(Toast);
export default {
  data () {
    return {
      orderPackage: {},
      images: [],
      orderPlaceshow: false,
      orderRenshow: false,
      orderRenAddshow: false,
      sum: "",
      count: 1,
      sumid: "",
      orderId: "",
      orderplaceList: [],
      MyBindInfoList: [],
      oldManList: "",
      oldmanForm: {
        OMIdCard: '',
        OMMobile: '',
        OMAddr: '',
        BindId: undefined
      },
    }
  },
  created () {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getSerPackage()
  },
  methods: {
    // 服务包详情
    getSerPackage () {
      console.log(this.$route.params);
      WeGetSerPackage({ spId: this.$route.params.SId }).then((res) => {
        this.orderPackage = res.data.data;
        this.images = res.data.data.ThemeImgUrl.split(",")
      });
    },
    close: function () {
      // console.log(123123);
      this.oldManList = ''
      this.oldmanForm.OMIdCard = ''
      this.oldmanForm.OMMobile = ''
      this.oldmanForm.OMAddr = ''
    },
    // 下单人
    orderRen: function () {
      this.orderRenshow = true
      this.getMyBindInfoPage()
    },
    getMyBindInfoPage: function () {
      WeGetMyBindInfoPage({ openID: getOpenId(), page: '1', limit: '999' }).then((res) => {
        if (res.data.code == '0') {
          if (res.data.data.length != '0') {
            this.MyBindInfoList = res.data.data;
            // this.orderListShow = false
          } else {
            // Toast.fail('未绑定老人信息');
            this.MyBindInfoList = []
            this.orderRenshow = false
            this.orderRenAddshow = true
          }
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    createOrder: function (row) {
      WeGenerateOrder({
        OType: 2,
        OSerKind: 0,
        OpenID: getOpenId(),
        SOId: this.orderPackage.SOId,
        OrgCode: this.$route.params.OrgCode,
      }).then((res) => {
        if (res.data.code == "0") {
          this.orderId = res.data.data
          this.saveOrderService(row);
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
    // 下单弹窗
    orderPlace: function (row) {
      console.log(row);
      this.orderPlaceshow = true
      if (this.sumid != row) {
        this.count = 1
        this.sum = 1;
      }
      if (this.orderId == '') {
        this.createOrder(row)
      } else {
        this.saveOrderService(row);
      }

    },
    //保存订单
    saveOrderService: function (row) {
      WeSaveOrderPackage({
        SPId: row,
        OrderId: this.orderId,
        OpenID: getOpenId(),
        SNum: this.sum,
      }).then((res) => {
        if (res.data.code == "0") {
          this.orderPlaceshow = true
          this.getOrderServiceList()
        }
      });
    },
    //已下订单列表
    getOrderServiceList: function () {
      WeGetOrderServiceList({ openID: getOpenId(), orderId: this.orderId }).then(
        (res) => {
          this.orderplaceList = res.data.data;
          // this.addOrder = res.data.data[0].OrderId
        }
      );
    },
    //添加数量
    changeAdd: function (row) {
      console.log(row);
      // this.OrderId = row.OrderId
      this.SId = row.SId
    },
    //删除数量
    changeDelet: function (row) {
      // this.OrderId = row.OrderId
      this.OSId = row.OSId
      this.SId = row.SId
    },
    //数量
    change: function (value) {
      console.log(value);
      if (value == '0') {
        this.deleteOrderService()
      } else {

        this.changeOrderService(value)
      }

    },
    // 添加删除后保存
    changeOrderService: function (row) {
      WeSaveOrderPackage({
        SPId: this.SId,
        OrderId: this.orderId,
        OpenID: getOpenId(),
        SNum: row,
      }).then((res) => {
        if (res.data.code == "0") {
          console.log(res.data.data);
          this.changeOrderServiceList()
        }
      });
    },
    // 删除订单服务项目
    deleteOrderService: function () {
      WeDeleteOrderService({
        OSId: this.OSId,
        OrderId: this.orderId,
        OpenID: getOpenId(),
      }).then((res) => {
        if (res.data.code == "0") {
          Toast.fail('已删除');
          this.changeOrderServiceList()
          // this.orderPlaceshow = false;
        }
      });
    },
    changeOrderServiceList: function () {
      WeGetOrderServiceList({ openID: getOpenId(), orderId: this.orderId }).then(
        (res) => {
          if (res.data.data.length != '0') {
            this.orderplaceList = res.data.data;
            this.addOrder = res.data.data[0].OrderId
          } else {
            this.show = false;
            this.orderPlaceshow = false;
          }

        }
      );
    },
    //下单
    placeOrder: function () {
      WePlaceOrder({ OpenID: getOpenId(), OrderId: this.orderId, BindId: this.oldManList }).then(
        (res) => {
          if (res.data.code == '0') {
            Toast.success('下单成功');
            removeOrderId()
            this.count = ''
            this.sum = ''
            this.sumid = ''
            this.orderId = ''
            this.orderplaceList = []
            this.show = false;
            this.orderPlaceshow = false;
            this.orderRenshow = false
          } else {
            Toast.fail(res.data.msg);
          }
        }
      );
    },
    // 添加老人信息
    submit () {
      console.log(this.oldmanForm);
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!reg.test(this.oldmanForm.OMIdCard)) {
        Toast.fail("身份证号码不正确");
      } else if (this.oldmanForm.OMMobile == '') {
        Toast.fail("请输入老人联系电话");
      } else if (this.oldmanForm.OMAddr == '') {
        Toast.fail("请输入老人现居住地址");
      } else {
        WeSaveBindInfo(
          {
            OpenID: getOpenId(),
            OMIdCard: this.oldmanForm.OMIdCard,
            OMMobile: this.oldmanForm.OMMobile,
            OMAddr: this.oldmanForm.OMAddr,
            BindId: this.oldmanForm.BindId,
          }
        ).then((res) => {
          if (res.data.code == '0') {
            Toast.success('绑定成功');
            this.orderRenAddshow = false
            this.orderRenshow = true
            this.oldmanForm.OMIdCard = ''
            this.oldmanForm.OMMobile = ''
            this.oldmanForm.OMAddr = ''
            this.oldmanForm.BindId = undefined
            this.getMyBindInfoPage()
          } else {
            Toast.fail(res.data.msg);
          }

        });
      }
    },
    add: function () {
      this.orderRenAddshow = true
    },
    // 编辑老人信息
    updata: function (row) {
      this.orderRenAddshow = true
      console.log(row);
      this.oldmanForm = Object.assign({}, row);
    },
  }
}
</script>

<style scoped>
.homebanner img {
  width: 100%;
  vertical-align: top;
}
.personal {
  /* position: absolute; */
  width: 100%;
  align-items: center;
  text-align: center;
}
.placeOrder {
  width: 100%;
  text-align: center;
  float: right;
  padding: 2px 5px;
  /* border: 1px solid; */
  border-radius: 10px 0;
  background-color: #ffc002;
}
.van-action-sheet {
  height: 500px !important;
}
.van-action-sheet__content {
  margin-bottom: 60px !important;
}

/deep/.van-stepper--round .van-stepper__plus {
  background-color: #ffc002 !important;
}
/deep/.van-stepper--round .van-stepper__minus {
  background-color: #ffc002 !important;
  border: 1px solid #ffc002 !important;
  color: #fff !important;
}
.tagClass {
  margin: 3px 3px 3px 0;
  padding: 2px;
  color: #fe5b47;
  border: 1px solid #ffa296;
  font-size: 14px;
  border-radius: 3px;
}
.title {
  font-size: 1.17em;
  font-weight: bold;
  margin: 1em 0;
  text-align: center;
}
.content {
  white-space: pre-line;
  width: 95%;
  margin: 0 auto;
}
.hidden {
  margin: 10px 0;
  font-size: 10px;
  color: #ccc;
  text-align: center;
}
.van-ellipsis--l1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
}
.orderYx {
  float: left;
  margin: 5px 10px;
  border-bottom: 1px solid #c6c6c6;
  width: 95%;
}
.orderRight {
  float: left;
  margin-left: 5px;
  font-size: 14px;
  color: #646566;
  width: 70%;
}
</style>